import { Grid } from '@material-ui/core';
import React from 'react';


interface WorkHeaderProps {
    workImg: string,
    work: string,
    enteprise: string,
    workInitDate: string,
    workEndtDate: string,
    workLink: string
}

const WorkHeader: React.FC<WorkHeaderProps> = (props) => {




    return (
        <>
            <Grid item container className="work-header" spacing={3}>
                <Grid item className="work-header-img"
                    xs={12}
                    sm={2}
                    md={2}>
                    <picture>
                        <a href={props.workLink} target="_blank" rel="noopener noreferrer">
                            <img data-src={"./resource/images/" + props.workImg} className="lazyload" alt="Logo da empesa" />
                        </a>
                    </picture>
                </Grid>
                <Grid container item
                    xs={12}
                    sm={6}
                    md={6}>
                    <Grid item xs={12}>

                        <h4><b>{props.work}</b></h4>
                    </Grid>
                    <Grid item xs={12}>
                        <h5 className="font-yellow"><a href={props.workLink} target="_blank" rel="noopener noreferrer"><b>{props.enteprise}</b></a></h5>
                    </Grid>
                    <Grid item xs={12}>
                        <h6 className="margin-t-10">{props.workInitDate} - {props.workEndtDate}</h6>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
export default WorkHeader;
