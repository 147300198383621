import React from 'react';
import { CardMedia, CardContent, Typography } from '@material-ui/core';

interface ButtonHeaderProps {
    img?: string,
    title?: string,
    isFileLocalByResource?: boolean

}

const ProjetoComponetBody: React.FC<ButtonHeaderProps> = (props) => {


    return (
        <>
            <CardMedia
                component="img"
                alt="Contemplative Reptile"
                height="140"
                image={props.isFileLocalByResource ? `./resource/images/post/${props.img}`: props.img}
                title="Contemplative Reptile"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    {props.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {props.children}
                </Typography>
            </CardContent>
        </>

    );
}


export default ProjetoComponetBody;