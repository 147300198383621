
import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import CalculoJuros from '../page/CalculoJuros';
import CvPage from '../page/CvPage';
import ImpressoraLogistica from '../page/ImpressoraLogistica';
import PostComponent from '../page/PostComponent';
import WhatsappComponent from '../page/WhatsappComponent';

function AppRouter() {
        return (
            <Router>
                <Route path="/" exact component={CvPage} />
                <Route path="/calcJuros" exact component={CalculoJuros} />
                <Route path="/wpp" exact component={WhatsappComponent} />
                <Route path="/post/:fileName" component={PostComponent} />
                <Route path="/print" exact component={ImpressoraLogistica} />
            </Router>
        );
    }

export default AppRouter;