import { Grid, GridSize, makeStyles } from '@material-ui/core';
import React from 'react'
import ListTecnologe from './ListTecnologe';
import WorkHeader from './WorkHeader'

interface ExperienceComponentProps {
    workImg: string,
    work: string,
    workLink: string,
    enteprise: string,
    workInitDate: string,
    workEndtDate: string,
    workSkils: string[],
    experienceComponentSize: GridSize,
}


const useStyles = makeStyles((theme) => ({
    containerExperience: {
        padding: "0 30px"
    }
}))

const ExperienceComponent: React.FC<ExperienceComponentProps> = ({
    workImg,
    work,
    workLink,
    enteprise,
    workInitDate,
    workEndtDate,
    workSkils,
    experienceComponentSize,
    children
}) => {
    const classes = useStyles();
    return (<>
        <Grid xs={12}
            sm={experienceComponentSize}
            md={experienceComponentSize}
            className={classes.containerExperience}>
            <Grid className="experience margin-b-50">
                <WorkHeader workImg={workImg} work={work} workLink={workLink} enteprise={enteprise} workInitDate={workInitDate} workEndtDate={workEndtDate} />
                <p className="margin-tb-30"> {children}</p>
                <ListTecnologe workSkils={workSkils} />
            </Grid>
        </Grid>
    </>);
}

export default ExperienceComponent;