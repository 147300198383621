import React from 'react';
import { CardActionArea, Card, Grid, makeStyles, Link as LinkMaterial } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ProjetoComponetBody from './ProjetoComponetBody';
import { EnumColors } from '../enum/EnumColors';

interface ButtonHeaderProps {
    id?: number,
    img?: string,
    title?: string,
    to?: any,
    redirect?: boolean,
    isFileLocalByResource?: boolean
}

const useStyles = makeStyles((theme) => ({
    projeto: {
        margin: "5px 10px",
    },
    projetoFont: {
        textDecoration: "none",
        color: EnumColors.BLACK,
        '&:focus': {
            textDecoration: "none",
            color: EnumColors.BLACK,
        },
        '&:hover': {
            color: "#0000009e",
            textDecoration: "none",
        }
    },
    root: {
        maxHeight: 345,
    }
}));

const ProjetoComponet: React.FC<ButtonHeaderProps> = (props) => {

    const classes = useStyles()

    return (
        <Grid item 
        xs={12}
        sm={4}
        md={2} 
        className={classes.projeto} 
        key={props.id}>
            <Card className={classes.root}>
                <CardActionArea >
                    {props.redirect ?
                        <LinkMaterial
                            href={props.to}
                            target="_blank"
                            rel="noreferrer"
                            className={classes.projetoFont}>
                            <ProjetoComponetBody img={props.img} title={props.title} isFileLocalByResource={props.isFileLocalByResource} >
                                {props.children} 
                            </ProjetoComponetBody>
                        </LinkMaterial>
                        :
                        <Link to={props.to} className={classes.projetoFont}>
                            <ProjetoComponetBody img={props.img} title={props.title}  isFileLocalByResource={props.isFileLocalByResource}>
                                {props.children}
                            </ProjetoComponetBody>
                        </Link>
                    }
                </CardActionArea>
            </Card>
        </Grid>

    );
}


export default ProjetoComponet;