import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import HeaderSession from '../HeaderSession';
import { EnumColors } from '../../enum/EnumColors';


const useStyles = makeStyles((theme) => ({

    headerBt: {
        right: "30px",
        zIndex: 100,
        padding: "10px 10px 10px",
        background: EnumColors.YELLOW,
        borderRadius: "10px",
        '&:hover': {
            backgroundColor: EnumColors.PURPLE_MIDLE,
            color: EnumColors.WHITE,
        },
        color: EnumColors.WHITE,
    },
    gridAbout: {
        padding: "15px",
    }
}));


const AboutMeSession: React.FC = () => {

    const classes = useStyles();

    const [showResults, setShowResults] = useState(false)
    const showAndHidden = () => setShowResults(!showResults)

    return (
        <>
            <div id="about"></div>

            <section className="second-section  section" id="sobreMim">

                <Grid container>
                    <Grid item container >
                        <HeaderSession title="SOBRE MIM" subtitle="Resumo do Historico Proficional" />
                        {/* <!-- col-sm-4 --> */}
                        <Grid xs={12}
                            sm={8}
                            md={6}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            className={classes.gridAbout}>
                            <p className="font-semi-white margin-b-30">Minhas habilidades como desenvolvedor de sistemas surgiram ao fazer projetos para os meus parentes e colegas. </p>
                            <p className="font-semi-white margin-b-30">Então, trabalhando no suporte de campo em uma das maiores concessionárias do Grupo Saga instaladas em Goiás pude ter contato com o gerente de TI da empresa, foi quando ao passar por mim pelo corredor me perguntou se eu tinha interesse de fazer uma experiência como desenvolvedor dentro da unidade.
                            </p>
                        </Grid>
                        <Grid xs={12} className={classes.gridAbout}>

                            <p className="font-semi-white margin-b-30">Assim inicialmente, minha jornada diária de trabalho foi dividida em dois períodos. Em um, fazia manutenção e em outro desenvolvia. Realizando pequenas correçõesno sistema interno da empresa que possuía uma estrutura de JAVA 8 no Back e JSF no Front banco de dados Oracle.</p>
                        </Grid>
                        {showResults ?
                            <Grid xs={12} className={classes.gridAbout}>

                                <div className="sobre-hidden-text">

                                    <p className="font-semi-white margin-b-30">Foi muito gratificante e sofrido os primeiros meses de trabalho, como ninguém da equipe tinha experiência com a tecnologia, todos os dias chegava em casa estudava até tarde. Para aplicar imediatamente no dia seguinte o que havia aprendido. Este período eu considero como o tempo que mais obtive crescimento pessoal e profissional. </p>
                                    <p className="font-semi-white font-semi-white margin-b-30">Após quase um ano trabalhando com inovação, prazos apertados e, cobrança por resultados semanais, recebi através de um antigo professor a informação de um processo seletivo para trabalhar na FIEG, no qual, eu fiz sem nenhuma pretensão. </p>
                                    <p className="font-semi-white margin-b-30">Fui aprovado e ocasionou em uma proposta de emprego. Pelo qual, com muitopesar e tristeza deixava a Saga e aceitava a FIEG, que incorpora as seguintes entidades (FIEG/SESI/SENAI/IEL/ICQ-BRASIL). </p>
                                    <p className="font-semi-white margin-b-30">Hoje é minha atual empresa. Nela atuo na manutenção do sistema financeiro interno. Na construção de evolutivas e corretivas, utilizando Java, JSF, ReactJS (padrão atual de desenvolvimento para novos projetos) e Angular JS (sistema legado).
                                    </p>
                                    <p className="font-semi-white margin-b-30"> Tenho como principal foco de trabalho um sistema que cuida da geração de contratos, serviços prestados e vendidos. Além de trabalhar com todo o fluxo de pagamento desde emissão de boletos até a baixa de pagamento TEF. </p>
                                    <p className="font-semi-white margin-b-30"> Até o momento na empresa tem dois projetos que possuo bastante afinidade. Comecei a construção dos dois e pude aplicar ao projeto estruturas que estava estudando e me chamaram a atenção.
                                    </p>
                                    <p className="font-semi-white margin-b-30">O primeiro foi o envio de SMS. Onde foi implementado o início estrutural de microservices. E tive a oportunidade de trabalhar com mensageria utilizando o RabbitMQ. Dentre outras novidades que não tinha trabalhado ainda, também foram incorporadas ao projeto. </p>
                                    <p className="font-semi-white margin-b-30">O segundo projeto é a emissão de nota fiscal. Foi um trabalho de grande aprendizado e muito desafiador. Pois além de desenvolver, trabalhei com a análise para gerar uma documentação. Entregar uma previsão de projeto entre outras coisas envolvidas na construção de documentação e análise. </p>
                                    <p className="font-semi-white margin-b-30">Como parte de mim, gosto de projetos que me desafie. Fazendo com que eu precise correr atrás de conhecimento para agregar na empresa melhorias que trazem facilidade e maior desempenho no trabalho. Acrescentando conhecimento para minha vida e profissão.</p>
                                </div>
                            </Grid>
                            : null}

                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center" >
                    <Button onClick={showAndHidden} className={classes.headerBt}>{showResults ? <Link to="about" spy={true} smooth={true} onClick={showAndHidden} >MENOS...</Link> : "MAIS"}</Button>
                </Grid>
            </section>
        </>
    );
}
export default AboutMeSession;
