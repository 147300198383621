import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { EnumColors } from '../../enum/EnumColors';
import ExperienceComponent from '../ExperienceComponent';
import HeaderSession from '../HeaderSession';

const useStyles = makeStyles((theme) => ({
    headerExprience: {
        padding: "0 30px",
    },
    headerBt: {
        right: "30px",
        zIndex: 100,
        padding: "10px 10px 10px",
        background: EnumColors.YELLOW,
        borderRadius: "10px",
        '&:hover': {
            backgroundColor: EnumColors.PURPLE_MIDLE,
            color: EnumColors.WHITE
        },
        color: EnumColors.WHITE
    }
}));

const ExperienceSession: React.FC = () => {

    const classes = useStyles();

    const [showResults, setShowResults] = useState(false)
    const showAndHidden = () => setShowResults(!showResults)



    return (
        <>
            {/* <!-- PORTIFOLIO --> */}
            <div id="work"></div>
            <section className="primary-section section">
                <Grid container >
                    <Grid item container >
                        <HeaderSession title="EXPERIÊNCIA" subtitle="Trabalhos" />
                        {/* <!-- Pagsguro Senior --> */}
                        <ExperienceComponent
                            workImg="pagseguro-logo.svg"
                            work="Engenheiro de Software Sênior"
                            workLink="https://pagseguro.uol.com.br"
                            enteprise="PagSeguro"
                            workInitDate="AGOSTO 2023"
                            workEndtDate="ATUAL"
                            experienceComponentSize={8}
                            workSkils={["JAVA", "MAVEN", "GRADLE", "JPA", "QUARKUS", "JSON", "ORACLE", "Hibernate", "SPOCK", "JEE", "REST-API", "SPRING BOOT", "DOCKER", "KUBERNETES", "EJB", "KAFKA"]}
                        >
                            Desenvolvimento e manutenção de Sistemas. Atualmente trabalho na Equipe de desenvolvimento e Evolução dos sistemas presente na PagSeguro.
                        </ExperienceComponent>
                        {/* <!-- Pagsguro Pleno --> */}
                        <ExperienceComponent
                            workImg="pagseguro-logo.svg"
                            work="Engenheiro de Software Pleno"
                            workLink="https://pagseguro.uol.com.br"
                            enteprise="PagSeguro"
                            workInitDate="OUTUBRO 2021"
                            workEndtDate="AGOSTO 2023"
                            experienceComponentSize={8}
                            workSkils={["JAVA", "MAVEN", "GRADLE", "JPA", "QUARKUS", "JSON", "ORACLE", "Hibernate", "SPOCK", "JEE", "REST-API", "SPRING BOOT", "DOCKER", "KUBERNETES", "EJB"]}
                        >
                            Desenvolvimento e manutenção de Sistemas. Atualmente trabalho na Equipe de desenvolvimento e Evolução dos sistemas presente na PagSeguro.
                        </ExperienceComponent>
                        {/* <!-- Pagsguro Junior --> */}
                        {showResults ?
                        <ExperienceComponent
                            workImg="pagseguro-logo.svg"
                            work="Engenheiro de Software Junior"
                            workLink="https://pagseguro.uol.com.br"
                            enteprise="PagSeguro"
                            workInitDate="JULHO 2020"
                            workEndtDate="NOVEMBRO 2021"
                            experienceComponentSize={8}
                            workSkils={["JAVA", "MAVEN", "GRADLE", "JPA", "QUARKUS", "JSON", "ORACLE", "Hibernate", "SPOCK", "JEE", "REST-API", "SPRING BOOT", "DOCKER", "KUBERNETES", "EJB"]}
                        >
                            Desenvolvimento e manutenção de Sistemas. Atualmente trabalho na Equipe de desenvolvimento e Evolução dos sistemas presente na PagSeguro.
                        </ExperienceComponent>: null}
                        {/* <!-- FIEG --> */}
                        {showResults ?
                        <ExperienceComponent
                            workImg="fieg-logo.svg"
                            work="DESENVOLVEDOR JAVA PLENO"
                            workLink="https://fieg.com.br"
                            enteprise="FIEG / SESI / SENAI / ICQ-BRASIL / IEL"
                            workInitDate="NOVEMBRO 2018"
                            workEndtDate="JUNHO 2020"
                            experienceComponentSize={12}
                            workSkils={["JAVA 8", "MAVEN", "REACT", "JPA", "JSF", "JSON", "SQL", "Hibernate", "Primefaces", "JEE", "REST-API", "SPRING BOOT", "SWARM", "EJB"]}
                        >
                            Desenvolvimento e manutenção de Sistemas. Atualmente trabalho na equipe que cuida do sistema financeiro interno da empresa. Atuando na geração e integração de pagamentos, geração de contrato etc. Também trabalho com a manutenção do sistema externo de pagamento
                        </ExperienceComponent> : null}

                        {/* <!-- GRUPO SAGA DEV --> */}
                        {showResults ?
                            <ExperienceComponent
                                workImg="saga-logo.png"
                                work="DESENVOLVEDOR JUNIOR DE T.I"
                                workLink="https://www.gruposaga.com.br/"
                                enteprise="GRUPO SAGA"
                                workInitDate="OUTUBRO 2017"
                                workEndtDate="OUTUBRO 2018"
                                experienceComponentSize={12}
                                workSkils={["JAVA 8", "MAVEN", "ANGULAR 6", "JPA", "JSF", "JSON", "ORACLE DATABASE", "Hibernate", "Primefaces", "Primeng", "JEE", "REST-API"]}
                            >
                                Desenvolvimento e manutenção de Sistemas. Trabalhei com os sistemas utilizados pela empresa, envolvido no controle e processo de venda de peças e veículos, realizava manutenções úteis para a administração do RH e contabilidade.
                                <br />
                                <br />
                                Simultâneo a isto, participei da equipe de inovações tecnológicas do grupo, na construção do sistema que facilitasse a venda.
                            </ExperienceComponent>
                            : null}
                        {/* <!-- GRUPO SAGA FIELD --> */}
                        {showResults ?
                            <ExperienceComponent
                                workImg="saga-logo.png"
                                work="AUXILIAR DE TI N4 (FIELD)"
                                workLink="https://www.gruposaga.com.br/"
                                enteprise="GRUPO SAGA"
                                workInitDate="NOVEMBRO 2016"
                                workEndtDate="OUTUBRO 2018"
                                experienceComponentSize={12}
                                workSkils={[]}
                            >
                                Atuei na equipe de manutenção de T.I do Grupo Saga, Auxiliando na manutenção das lojas do grupo. Fazendo manutenção corretiva e preventiva de máquinas e da estrutura de rede da Empresa
                            </ExperienceComponent>
                            : null}

                        {/* <!-- SENAC --> */}
                        {showResults ?
                            <ExperienceComponent
                                workImg="senac-logo.png"
                                work="ESTÁGIO EM TI"
                                workLink="https://www.go.senac.br/"
                                enteprise="SANAC GO"
                                workInitDate="MAIO 2015"
                                workEndtDate="JULHO 2016"
                                experienceComponentSize={12}
                                workSkils={[]}
                            >
                                Atuei na equipe de manutenção de T.I, Auxiliando na manutenção das unidades e nos Laboratórios. Fazendo manutenção corretiva e preventiva de máquinas e da estrutura de rede da Empresa
                            </ExperienceComponent>
                            : null}
                    </Grid>
                </Grid>
                {/* <!-- container --> */}
                {/* todo: quando cliecar no bt de menos fazer subir para o local designado */}


                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center" >
                    <Button onClick={showAndHidden} className={classes.headerBt}>{showResults ? <Link to="work" spy={true} smooth={true} onClick={showAndHidden} >MENOS...</Link> : "MAIS"}</Button>
                </Grid>
            </section>

        </>
    );
}

export default ExperienceSession;
