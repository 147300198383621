import React from 'react';
import AboutMeSession from '../components/cvComponents/AboutMeSession';
import BlogSession from '../components/cvComponents/BlogSession';
import DescribeSession from '../components/cvComponents/DescribeSession';
import ExperienceSession from '../components/cvComponents/ExperienceSession';
import FooterSession from '../components/cvComponents/FooterSession';
import GraduationSession from '../components/cvComponents/GraduationSession';
import HeaderMenu from '../components/cvComponents/HeaderMenu';
import ProjectSession from '../components/cvComponents/ProjectSession';


const CvPage: React.FC = () => {

    return (
        <>
            <HeaderMenu />
            <DescribeSession />
            <BlogSession />
            <ExperienceSession />
            <AboutMeSession />
            <GraduationSession />
            <ProjectSession />
            <FooterSession />
        </>
    );
}
export default CvPage;