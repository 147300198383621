import { Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { EnumColors } from '../enum/EnumColors';


interface SocialIconProps {
    myLink: string,
    myClassName: React.ReactNode,
    Myicon: string
}

const useStyle = makeStyles((theme) => ({
    socialIc: {
        height: "35px",
        width: "35px",
        borderRadius: "100%",
        zIndex: 1,
        padding: "5px",
        background: EnumColors.PURPLE_MIDLE,
        color: EnumColors.WHITE,
        '&:hover': {
            
            position: "initial",
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            zIndex: 1,
            borderRadius: "20px",
            background: EnumColors.YELLOW,
            transition: "all .2s ease",
        }
    }
})
)


const SocialIcon: React.FC<SocialIconProps> = (props) => {
    const classes = useStyle();
    return (
        <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            xs={1}
        >
            <Link
                href={props.myLink}
                className={classes.socialIc}
                target="_blank"
                rel="noreferrer">
                {props.myClassName}
            </Link>
        </Grid>
    );
}
export default SocialIcon;
