import { Grid, makeStyles } from '@material-ui/core';
import React from 'react'
import { EnumColors } from '../enum/EnumColors';

interface ListTecnologeProps {
    workSkils?: String[]
}

const useStyles = makeStyles((theme) => ({
    headerTecnologi: {
        color: '#FFA600',
        marginBottom: '20px',
    },
    skils: {
        marginBottom: "10px",
        color: EnumColors.GRAY,
        paddingLeft: "20px"
    }
}));

const ListTecnologe: React.FC<ListTecnologeProps> = ({ workSkils }) => {

    const classes = useStyles();


    return (<>
        <Grid item>
            {workSkils && workSkils?.length > 0 ? (
                <h6 className={classes.headerTecnologi}>TECNOLOGIAS</h6>
            ) : ''}
            <Grid container item >
                {workSkils && workSkils.map((data, index) => (
                    <Grid item
                        xs={6}
                        sm={3}
                        md={3}
                        key={index} className={classes.skils}>
                        {data.toUpperCase()}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    </>);
}

export default ListTecnologe;