import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import HeaderSession from '../HeaderSession';
import ProjetoComponet from '../ProjetoComponet';


const useStyle = makeStyles((theme) => ({

    btProject: {
        '&:hover': {
            backgroundColor: "#0000002b",
            borderRadius: "15px",
        }
    }, projetoContet: {
        margin: "0 10px",
    },
    projeto: {
        margin: "5px 10px",
        '&:hover': {
            color: "#292b2cbf"
        }
    }

}));

const ProjectSession: React.FC = () => {
    const classes = useStyle();

    return (
        <>


            {/* <!-- Projetos provisoriameinte aqui --> */}
            <section className="second-section  section" id="projetos">
                <Grid container >
                    <Grid item container className={classes.projetoContet}>
                        <HeaderSession title="Projetos" subtitle="Alguns Projetos Desenvolvidos" />
                    </Grid>


                    <Grid item container xs={12} direction="row"
                        justifyContent="center"
                        alignItems="flex-start">

                        <ProjetoComponet img="https://camilarcarvalho.com.br/wp-content/uploads/2018/02/como-criar-um-link-para-whatsapp-link-encurtado-camila-carvalho.png" title="WhatsApp" to="wpp" >
                            Esse projeto e uma pagina para abrir o numero no whatsapp sem precisar adicionar na agenda
                        </ProjetoComponet>

                        <ProjetoComponet img="https://i.ytimg.com/vi/U_fcoQ50wWU/maxresdefault.jpg" title="Calculo de Juros" to="calcJuros" >
                            Esse projeto faz o calculo do juros composto.
                        </ProjetoComponet>

                        <ProjetoComponet img="https://www.educative.io/v2api/editorpage/5819622709264384/image/4750982421413888" title="[GO] Api Usuário" to="https://github.com/matheusCalaca/userAPI" redirect={true} >
                            O objetivo deste projeto é estudar um pouco da linguagem de programação GO. Construido uma api para cadastro de Usuário
                        </ProjetoComponet>

                        <ProjetoComponet img="https://repository-images.githubusercontent.com/269177666/13df7000-a803-11ea-8df7-83beea9b8d43" title="[REACT] E-COLETA" to="https://github.com/matheusCalaca/nlw" redirect={true} >
                            Projeto feito com base na semana react, proporcionado pela rocketseat.
                        </ProjetoComponet>

                        <ProjetoComponet img="https://cakeerp.com/wp-content/uploads/2018/10/controle-de-contas-a-receber.jpg" title="Projeto Faturas" to="https://github.com/matheusCalaca/fatura_front" redirect={true} >
                            Esse projeto tem como objetivo você salvar uma fatura e poder fazer o controle. [Front]
                        </ProjetoComponet>

                        <ProjetoComponet img="https://superdigital.com.br/blog/images/IMAGEMBLOG_BANCODIGITAL.jpg" title="Projeto Faturas" to="https://github.com/matheusCalaca/fatura_back" redirect={true} >
                            Esse projeto tem como objetivo você salvar uma fatura e poder fazer o controle. [Back]
                        </ProjetoComponet>

                        <ProjetoComponet img="https://i.ytimg.com/vi/kxU2KCrVMqs/maxresdefault.jpg" title="Game C Pong" to="https://github.com/matheusCalaca/GameCPong" redirect={true} >
                            projeto para replicar o game pong, estudar orientação objeto, aprimorar conhecimentos em javascript.
                        </ProjetoComponet>

                        <ProjetoComponet img="https://static.wixstatic.com/media/7f132f_3e243c8a94e4430baeb030c3f2f7e0bd~mv2.jpg/v1/fill/w_320,h_320,q_90/7f132f_3e243c8a94e4430baeb030c3f2f7e0bd~mv2.jpg" title="api rest thorntail" to="https://github.com/matheusCalaca/api-rest-thorntail-example" redirect={true} >
                            Entender um pouco mais de como funciona o thorntail, e aprender sobre o swagger
                        </ProjetoComponet>

                    </Grid>
                </Grid>
            </section>

        </>
    );
}

export default ProjectSession;
