import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useParams } from 'react-router-dom';
import HeaderMenuSubPages from '../components/cvComponents/HeaderMenuSubPages';
import remarkMermaidPlugin from 'remark-mermaid-plugin';
import rehypeRaw from "rehype-raw";
import rehypeStringify from "rehype-stringify";
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialOceanic } from 'react-syntax-highlighter/dist/esm/styles/prism';


const useStyle = makeStyles((theme) => ({
    postClass: {
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        '& h1': {
            fontSize: '2rem',
            fontWeight: 'bold',
            marginBottom: '20px',
        },
        '& h2': {
            fontSize: '1.5rem',
            fontWeight: 'bold',
            marginTop: '30px',
            marginBottom: '10px',
        },
        '& p': {
            lineHeight: '1.5',
            marginTop: '0',
            marginBottom: '20px',
        },
        '& a': {
            color: '#0077c0',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        '& del': {
            textDecoration: 'line-through',
        },
        '& table': {
            borderCollapse: 'collapse',
            width: '100%',
            '& th, td': {
                padding: '10px',
                border: '1px solid #ccc',
            },
            '& th': {
                background: '#f7f7f7',
                fontWeight: 'bold',
                textAlign: 'left',
            },
            '& th:first-child, td:first-child': {
                textAlign: 'center',
            },
            '& th:last-child, td:last-child': {
                textAlign: 'right',
            },
        },
        '& .contains-task-list': {
            listStyleType: 'none',
            padding: '0',
            '& .task-list-item': {
                display: 'flex',
                alignItems: 'center',
                lineHeight: '1.5',
                marginBottom: '10px',
                '& input': {
                    marginRight: '10px',
                },
                '& input[disabled]': {
                    opacity: '0.5',
                },
                '& input[disabled] + *': {
                    opacity: '0.5',
                },
                '& input:checked + *': {
                    textDecoration: 'line-through',
                },
            },
        },
        '& pre': {
            background: '#f7f7f7',
            color: '#333',
            fontFamily: 'Consolas, monospace',
            padding: '20px',
            whiteSpace: 'pre-wrap',
        }
    }

}));


const PostComponent: React.FC = () => {
    const classes = useStyle();

    const [markdown, setMarkdown] = useState('');

    const { fileName } = useParams<{ fileName: string }>();

    async function getMarkdown() {
        import(`../resource/post/${fileName}.md`)
            .then((markdownFile) => {
                fetch(markdownFile.default)
                    .then((response) => response.text())
                    .then((text) => setMarkdown(text));
            });
    }

    useEffect(() => {
        getMarkdown();
    }, []);

    const renderers = {
        code({ node, inline, className, children, ...props }: any) {
            const match = /language-(\w+)/.exec(className || '');
            if (match && match[1] === 'mermaid') {
                // Ignorar blocos mermaid
                return <div>{children}</div>;
            }
            return !inline && match ? (
                <SyntaxHighlighter style={materialOceanic} language={match[1]} PreTag="div" {...props}>
                    {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
            ) : (
                <code className={className} {...props}>
                    {children}
                </code>
            );
        }
    };

    return (<div>
        <div>
            <HeaderMenuSubPages />
        </div>
        {/* <DescribeSession /> */}
        <div className={classes.postClass}>
            {markdown ? <ReactMarkdown 
            children={markdown} 
            remarkPlugins={[
                remarkMermaidPlugin,
                remarkGfm
            ]}
            rehypePlugins={[rehypeRaw, rehypeStringify] as any} 
            components={renderers}

            /> : <p>Loading...</p>}
        </div>

    </div>);
}
export default PostComponent;