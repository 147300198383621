import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react'
import { Link } from 'react-scroll'
import { EnumColors } from '../enum/EnumColors';

interface GraduationComponentProps {
    title: string,
    institution: string,
    startYear?: string,
    endYear?: string,
}


const useStyles = makeStyles((theme) => ({
    link: {
        color: EnumColors.GRAY,
        '&:hover': {
            color: EnumColors.PURPLE_MIDLE,
        }
    }
}));

const GraduationComponent: React.FC<GraduationComponentProps> = ({ title, institution, startYear, endYear, children }) => {

    const [showResults, setShowResults] = useState(false)
    const showAndHidden = () => setShowResults(!showResults)

    const classes = useStyles();
    return (<>
        <Grid xs={12}
            sm={8}
            md={8}
            className="education-wrapper">
            <Grid className="education margin-b-50">
                <h4><b>{title}</b></h4>
                <h5 className="font-yellow"><b>{institution}</b></h5>
                <h6 className="font-lite-black margin-t-10">{startYear} - {endYear}</h6>
                <p className="margin-tb-30">
                    {showResults ? children : null}
                </p>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center" >
                    {showResults ? "" : <Button onClick={showAndHidden} className={classes.link} > <Link to="graduacao" spy={true} smooth={true} onClick={showAndHidden} ></Link>Informações do curso</Button>}
                    {!showResults ? "" : <Button onClick={showAndHidden} className={classes.link} > <Link to="graduacao" spy={true} smooth={true} onClick={showAndHidden} ></Link> Menos</Button>}
                </Grid>
            </Grid>
        </Grid>
    </>);
}

export default GraduationComponent;