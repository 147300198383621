import { Button, Card, CardActions, CardContent, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import ProjetosHeader from '../components/projetoComponets/ProjetosHeader';
import { EnumColors } from '../enum/EnumColors';

const useStyle = makeStyles((theme) => ({
    headerMenu: {
        backgroundColor: EnumColors.YELLOW,
        color: EnumColors.PURPLE_MIDLE,
        fontSize: "20px"
    },
    gridMargin: {
        marginTop: "15px"
    },
    gridPaddingBottom: {
        paddingBottom: "15px"
    },
    headerBt: {
        zIndex: 100,
        padding: "10px 10px 10px",
        background: EnumColors.YELLOW,
        borderRadius: "10px",
        margin: theme.spacing(2),
        '&:hover': {
            backgroundColor: EnumColors.PURPLE_MIDLE,
            color: EnumColors.WHITE
        },
        color: EnumColors.WHITE
    }
}));

const CalculoJuros: React.FC = () => {
    const classes = useStyle();

    const jurosCalc = {
        valorInicial: null,
        juros: null,
        dias: null,
        valorJurosDiaPorcentagem: null,
        valorFuturo: null,
    };

    const [currentJuros, setCurrentJuros] = useState<any>(jurosCalc);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCurrentJuros({ ...currentJuros, [name]: value });
    };


    function calcValores() {
        //todo: fazer a parte de arrendodamento
        let ValJurosDia = (Math.pow((1 + (currentJuros.juros / 100)), (1 / 30)) - 1);
        let valJurosDiaPorcentagem = ValJurosDia * 100;
        let valorFuturo = currentJuros.valorInicial * Math.pow((1 + (ValJurosDia)), currentJuros.dias);
        console.log(valJurosDiaPorcentagem);

        setCurrentJuros({ ...currentJuros, 'valorJurosDiaPorcentagem': aroundValue(valJurosDiaPorcentagem),
         'valorFuturo': aroundValue(valorFuturo) });
    }

    function aroundValue(value: number): string {
        return (Math.round(value * 100) / 100).toFixed(2);
    }


    return (<>
        <ProjetosHeader />
        <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Grid item
                xs={12}>
                <Card  >
                    <CardContent>
                        <Typography variant="h3" component="h1" className={classes.gridPaddingBottom}>
                            Calculo com Juros compostos
                        </Typography>

                        <Grid item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center" className={classes.gridMargin}>
                            <Typography variant="body1" component="p">
                                Eu estou emprestando <TextField id="valorInicial" name="valorInicial" type="number" value={currentJuros.valorInicial} onChange={handleInputChange} /> R$
                                <br />
                                com juros de <TextField id="juros" name="juros" type="number" value={currentJuros.juros} onChange={handleInputChange} />  % ao mês
                            </Typography>
                        </Grid>
                        <Grid item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center" className={classes.gridMargin}>
                            <Typography variant="body1" component="p">

                                por um periodo de <TextField id="dias" name="dias" type="number" value={currentJuros.dias} onChange={handleInputChange} />  dias
                            </Typography>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center" className={classes.gridMargin}>
                            <Button size="small" onClick={calcValores} color="primary"
                                className={classes.headerBt}>Calcular</Button>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item
                xs={12}>

                <Card  >
                    <CardContent>
                        <Typography variant="h4" component="h1" className={classes.gridPaddingBottom}>
                            Resultado do Calculo
                        </Typography>

                        <Grid item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center" className={classes.gridMargin}>
                            <Typography variant="body2" component="p">
                                Estou cobrando um juros de <TextField id="valorJurosDiaPorcentagem" type="number" value={currentJuros.valorJurosDiaPorcentagem} disabled />  % ao dia
                                <br />
                                No final devo receber  <TextField id="valorFuturo" type="number" value={currentJuros.valorFuturo} disabled /> R$
                            </Typography>
                        </Grid>
                    </CardContent>
                </Card>

            </Grid>
        </Grid>

    </>);
}
export default CalculoJuros;