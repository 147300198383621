import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import GraduationComponent from '../GraduationComponent';
import HeaderSession from '../HeaderSession';

function GraduationSession() {
    const useStyles = makeStyles((theme) => ({
        gridAbout: {
            padding: "15px",
        }
    }));

    const classes = useStyles();

    return (
        <>
            <div id="graduacao"></div>

            <section className="education-section section">
                <Grid container>
                    <HeaderSession title="FORMAÇÃO" subtitle="Formação Acadêmica" />



                    <Grid item container
                        xs={12}
                        sm={8}
                        md={8}
                        className={classes.gridAbout} >
                        {/* Pós-Graduação em Liderança Técnica */}
                        <GraduationComponent title="Pós-Graduação em Liderança Técnica" institution="Faculdade Brasília / Full Cycle LTDA" startYear="2024" endYear="2025">
                            Pós-Graduação em Liderança Técnica é projetada para capacitar profissionais a combinar habilidades técnicas especializadas com competências sólidas de liderança. Com foco em desenvolver líderes que possam guiar equipes técnicas complexas, o programa oferece conhecimentos avançados em gestão de projetos, comunicação eficaz, resolução de problemas e tomada de decisões estratégicas. Os benefícios incluem a capacidade de liderar com confiança em ambientes altamente técnicos, promover a inovação através da integração de conhecimentos técnicos e gerenciais, e influenciar positivamente o crescimento e o sucesso organizacional em um mercado cada vez mais competitivo e dinâmico.
                        </GraduationComponent>

                        {/* Pós-Graduação Engenharia de Software */}

                        <GraduationComponent title="Pós-Graduação Engenharia de Software" institution="Pontifícia Universidade Católica de Minas Gerais" startYear="2021" endYear="2022">
                            Para se desenvolver sistemas de informação automatizados de qualidade e que atendam às reais necessidades de seus usuários, é de suma importância a utilização de metodologias que conduzam de forma eficiente e eficaz esse processo. O curso apresenta uma visão crítica do emprego da Engenharia de Software no processo de desenvolvimento de sistemas de informação para os mais variados ambientes organizacionais, abordando, além de técnicas tradicionais de desenvolvimento de software, assuntos estado-da-arte, como desenvolvimento web, desenvolvimento para dispositivos móveis, PMBOK, SCRUM, reutilização de software, modelos MPS-Br/CMMI, dentre outros.
                        </GraduationComponent>

                        {/* Graduação em Análise e Desenvolvimento de Sistemas */}

                        <GraduationComponent title="análise e desenvolvimento de sistemas" institution="Anhanguera Educacional" startYear="2014" endYear="2016">
                            O profissional formatodo tem noções sobre Bancos de Dados, sistemas baseados em web (como serviços bancários pela internet) e programação distribuída, que conecta computadores em rede para que funcionem como se fossem um só
                            computador. Administração, contabilidade, economia, estatística e inglês também fazem parte do currículo. Além disso, habilidades para leitura e interpretação de textos. E um profissional versáteil, dinâmico, que sabe trabalhar
                            em equipe e possam ter contato com o usuário final do sistema.
                            <br />
                            Estão como principais caracteristia de um profissional formado, cria, projeta, desenvolve e configura programas, softwares e sistemas para inúmeras empresas.
                            Faz auditoria de sistemas para avaliar possíveis erros e falhas. Além disso, o tecnólogo em Análise e Desenvolvimento de Sistemas está apto a prestar consultoria e desenvolver pesquisas.
                        </GraduationComponent>

                        {/* Graduação em Técnico em Informática para Internet */}

                        <GraduationComponent title="TÉCNICO EM INFORMATICA PARA INTERNET" institution="faculdade integrada da grande fortaleza (FGF)" startYear="2013" endYear="2015" >
                            O profissional formado no Curso Técnico em Informática para Internet desenvolve programas de computador para internet, seguindo as especificações e paradigmas da lógica e das linguagens de programação. Ele é apto a desenvolver
                            <br />
                            sites e aplicações para internet utilizando recursos e plataformas web, utilizando ferramentas de análise para construir interfaces e aplicativos com elevado grau de usabilidade e acessibilidade. Ele aprende a identificar
                            e conhecer o funcionamento e o relacionamento existente entre os componentes, acessórios e periféricos de um computador, identificar e instalar softwares, realizar operações essenciais aos sistemas operacionais e serviços
                            de Internet, construir rotinas e scripts, instalar e administrar sistemas para Internet, configurar e instalar, de forma adequada, os recursos necessários à conectividade em uma rede de computadores com sistemas para Internet,                                configurar e instalar serviços de redes de computadores e proteção à informação.
                        </GraduationComponent>
                    </Grid>
                    {/* <!-- education-wrapper --> */}
                    {/* <!-- col-sm-8 --> */}
                </Grid>
            </section>

        </>
    );
}

export default GraduationSession;
