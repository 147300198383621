import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import HeaderSession from '../HeaderSession';
import ProjetoComponet from '../ProjetoComponet';
import filesDados from '../../resource/artigosTutorias.json';
import { EnumColors } from '../../enum/EnumColors';


interface Post {
    id?: string;
}

interface Artigos {
    id: number;
    titulo: string;
    imagem: string;
    resumo: string;
    filename: string;
    categoria: string;
}

const useStyle = makeStyles((theme) => ({

    btProject: {
        backgroundColor: EnumColors.WHITE, // Botão branco
        color: EnumColors.PURPLE_DARK, // Cor do texto (igual ao fundo)
        borderRadius: "8px",
        padding: "10px 20px",
        margin: "0 10px",
        fontWeight: "bold",
        '&:hover': {
            backgroundColor: EnumColors.YELLOW, // Hover suave
        },
        '&:focus': {
            backgroundColor: EnumColors.WHITE, // Active suave
            color: EnumColors.YELLOW, // Mantém a cor do texto
        },
        '&:active': {
            backgroundColor: EnumColors.WHITE, // Active suave
            color: EnumColors.YELLOW, // Mantém a cor do texto
        }
    }, projetoContet: {
        margin: "0 10px",
    },
    projeto: {
        margin: "5px 10px",
        '&:hover': {
            color: "#292b2cbf"
        }
    }, projetoMenu: {
        marginBottom: "15px",
        marginTop: "-10px"
    }, headerBt: {
        right: "30px",
        zIndex: 100,
        padding: "10px 10px 10px",
        background: EnumColors.YELLOW,
        borderRadius: "10px",
        '&:hover': {
            backgroundColor: EnumColors.PURPLE_MIDLE,
            color: EnumColors.WHITE
        },
        color: EnumColors.WHITE
    }, btShowHidden: {
        marginBottom: "15px",
        marginTop: "15px"
    }

}));

const BlogSession: React.FC = () => {
    const classes = useStyle();
    const [selectedCategory, setSelectedCategory] = React.useState('Todos');
    const [showResults, setShowResults] = useState(false)
    const [maxToShow, setMaxToShow] = useState(10); // Define o número máximo de artigos a serem mostrados


    const artigos: Artigos[] = filesDados.artigos;

    const artigosFiltrados = selectedCategory === 'Todos'
        ? artigos // Mostra todos os artigos
        : artigos.filter(artigo => artigo.categoria === selectedCategory); // Filtra por categoria
    const showAndHidden = () => {
        setShowResults(!showResults);
        // Ao alternar showResults para false, redefine o número máximo de artigos a serem mostrados para 10
        if (!showResults) {
            setMaxToShow(10);
        }
    }

    return (
        <>
            {/* <!-- Projetos provisoriameinte aqui --> */}
            <section className="second-section  section" id="projetos"> {/* Section to display tutorials/articles */}
                <Grid container >
                    <Grid item container className={classes.projetoContet}> {/* Container for the header */}
                        <div id="blog"></div>
                        <HeaderSession title="Tutoriais/Artigos" subtitle="Alguns Tutoriais/Artigos Desenvolvidos" /> {/* Header component with title and subtitle */}
                    </Grid>
                    <Grid item container xs={12} justifyContent="center" className={classes.projetoMenu}> {/* Container for category filter buttons */}
                        <Grid item>
                            <Button className={classes.btProject} variant="outlined" onClick={() => setSelectedCategory('Todos')}>Todos</Button> {/* Button to filter all categories */}
                        </Grid>
                        <Grid item>
                            <Button className={classes.btProject} variant="outlined" onClick={() => setSelectedCategory('Designer')}>Padrões de Projeto</Button> {/* Button to filter "Designer" category */}
                        </Grid>
                        <Grid item>
                            <Button className={classes.btProject} variant="outlined" onClick={() => setSelectedCategory('Tutorial')}>Tutorial</Button> {/* Button to filter "Tutorial" category */}
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                    > {/* Container for displaying the articles */}
                        {artigosFiltrados
                            .sort((a, b) => a.id - b.id) // Sort articles by ID in ascending order
                            .reverse() // Reverse the order to display newest first
                            .slice(0, showResults ? artigosFiltrados.length : maxToShow) // Mostra todos os artigos se showResults for true, senão, mostra apenas maxToShow
                            .map((item) => (
                                <ProjetoComponet key={item.id} img={item.imagem} title={item.titulo} to={`post/${item.filename}`} isFileLocalByResource={true}> {/* Component to display each article */}
                                    {item.resumo} {/* Article summary */}
                                </ProjetoComponet>
                            ))}
                    </Grid>
                </Grid>

                {artigosFiltrados.length > 10 ? <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.btShowHidden} >
                    <Button onClick={showAndHidden} className={classes.headerBt}>{showResults ? <Link to="blog" spy={true} smooth={true} onClick={showAndHidden} >MENOS...</Link> : "MAIS"}</Button>
                </Grid> : null}

            </section>


        </>
    );
}

export default BlogSession;
