import { Button, Grid, makeStyles } from '@material-ui/core';
import { Email, LinkedIn, LocationOn, WhatsApp } from '@material-ui/icons';
import React from 'react';
import { EnumColors } from '../../enum/EnumColors';
import ButtonHeader from '../ButtonHeader';

const useStyles = makeStyles((theme) => ({
    header: {
        width: "90%",
        borderRadius: "15px",
        background: EnumColors.YELLOW,
        color: EnumColors.WHITE,
        zIndex: 100,
    },
    headerBt: {
        zIndex: 100,
        padding: "10px 10px 10px",
        background: EnumColors.YELLOW,
        borderRadius: "10px",
        margin: theme.spacing(2),
        '&:hover': {
            backgroundColor: EnumColors.PURPLE_MIDLE,
            color: EnumColors.WHITE
        },
        color: EnumColors.WHITE
    },
    headerContainer: {
        top: "20px",
        position: "absolute"
    },
    whidhHeader: {
        width: "90%"
    }
}));

function HeaderMenu() {


    const classes = useStyles();
    return (
        <Grid container direction="column"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
            className={classes.headerContainer}>
            <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.header}
            >
                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    xs={12}
                >
                    <Grid
                        item
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        xs={12}
                        sm={6}
                        md={3}
                    >
                        <ButtonHeader link="https://goo.gl/maps/7WCZ8vEzs23UEHzcA" startIcon={<LocationOn />} msg="Goiânia - Goiás"></ButtonHeader>
                    </Grid>
                    <Grid
                        item
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        xs={12}
                        sm={6}
                        md={3}
                    >
                        <ButtonHeader link="https://api.whatsapp.com/send?phone=5562982507424" startIcon={<WhatsApp />} msg="(62) 98250-7424"></ButtonHeader>
                    </Grid>

                    <Grid
                        item
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        xs={12}
                        sm={6}
                        md={3}
                    >
                        <ButtonHeader link="mailto:matheusfcalaca@gmail.com" startIcon={<Email />} msg="matheusfcalaca@gmail.com"></ButtonHeader>
                    </Grid>


                    <Grid
                        item
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        xs={12}
                        sm={6}
                        md={3}
                    >
                        <ButtonHeader link="https://www.linkedin.com/in/matheuscalaca/" startIcon={<LinkedIn />} msg="Matheus Calaça"></ButtonHeader>
                    </Grid>

                </Grid>
            </Grid>


            <Grid item container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                className={classes.whidhHeader}
            >
                <Grid item

                >
                    <Button
                        className={classes.headerBt}
                        variant="contained"
                        href="https://docs.google.com/document/d/1B4F6Fz5_R9U01hOmWz1-sqLQQgJT2lU60Y8wHTXfyhg/edit?usp=sharing"
                        target="_blank"
                        color="primary">
                        Download CV
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default HeaderMenu;
