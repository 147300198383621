import { Grid, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Email, LinkedIn, LocationOn, WhatsApp } from '@material-ui/icons';
import React from 'react';
import { EnumColors } from '../../enum/EnumColors';
import ButtonHeader from '../ButtonHeader';

const useStyles = makeStyles((theme) => ({
    headerSub: {
        background: EnumColors.YELLOW,
        color: EnumColors.WHITE,
        zIndex: 100,
    },
    headerSubContainer: {
        top: "20px"
    },
    whidhHeaderSub: {
        width: "90%"
    },
    headerSubTitle: {
        background: EnumColors.YELLOW,
        color: EnumColors.WHITE,
        width: "100%",
        fontSize: '2.5rem',
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase',
        textDecoration: 'none',
        '&:hover': {
            color: EnumColors.PURPLE_MIDLE
        },
        '& a': {
            textDecoration: 'none',
            '&:hover': {
                color: EnumColors.PURPLE_MIDLE
            },
            '&:active': {
                color: EnumColors.WHITE
            },
            '&:visited': {
                color: EnumColors.WHITE
            },
        }
    }
}));

function HeaderMenuSubPages() {


    const classes = useStyles();
    return (
        <Grid container direction="column"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
            className={classes.headerSubContainer}>
            <Grid
                item
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.headerSubTitle}
            >
                <Link to={"/"}>
                    MATHEUS CALAÇA
                </Link>
            </Grid>

            <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.headerSub}
            >
                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    xs={12}
                >
                    <Grid
                        item
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        xs={12}
                        sm={6}
                        md={3}
                    >
                        <ButtonHeader link="mailto:matheusfcalaca@gmail.com" startIcon={<Email />} msg="matheusfcalaca@gmail.com"></ButtonHeader>
                    </Grid>


                    <Grid
                        item
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        xs={12}
                        sm={6}
                        md={3}
                    >
                        <ButtonHeader link="https://www.linkedin.com/in/matheuscalaca/" startIcon={<LinkedIn />} msg="Matheus Calaça"></ButtonHeader>
                    </Grid>

                </Grid>
            </Grid>


            <Grid item container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                className={classes.whidhHeaderSub}
            >
            </Grid>
        </Grid>
    );
}

export default HeaderMenuSubPages;
