import { Grid, makeStyles } from '@material-ui/core';
import React from 'react'

interface HeaderSessionProps {
    title?: String,
    subtitle?: String,
}

const useStyles = makeStyles((theme) => ({
    headerExprience: {
        padding: "0 45px",
    }
}));

const HeaderSession: React.FC<HeaderSessionProps> = (props) => {

    const classes = useStyles();

    return (
        <Grid item 
        xs={12}
        sm={4}
        md={4} 
        className={classes.headerExprience}>
            <Grid className="heading">
                <h3><b>{props.title}</b></h3>
                <h6 className="font-lite-black"><b>{props.subtitle}</b></h6>
            </Grid>
        </Grid>
    );
}

export default HeaderSession;